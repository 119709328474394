
import PageTabs from '@/components/PageTabs.vue';
import GovernanceVoterItem from '@/components/GovernanceVoterItem.vue';
import GovernanceProposalItem from '@/components/GovernanceProposalItem.vue';
import GovernanceProposalItem2 from '@/components/GovernanceProposalItem2.vue';
import { Component, Vue } from 'vue-property-decorator';
import BigNumber from 'bignumber.js';
import { UserModule } from '@/store/modules/user';
import * as echarts from 'echarts/core';
import { EChartsOption } from 'echarts';
import {
  GridComponent,
  GridComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  LegendComponent,
} from "echarts/components";

import { LineChart, LineSeriesOption } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { UniversalTransition } from 'echarts/features';
import { shortenNumber, weiBigNumberToNormalValueFixed } from '@/utils/tools';

echarts.use([
  GridComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
  TooltipComponent,
  LegendComponent,
]);

type EChartsOption = echarts.ComposeOption<
GridComponentOption | LineSeriesOption | TooltipComponentOption
>;


@Component({
  components: {GovernanceProposalItem, GovernanceVoterItem, PageTabs, GovernanceProposalItem2},
  apollo: {
    proposalTopLikes: {
      query: require("../../apollo/graphql/ProposalTopLikes.gql"),
      variables: () => {
        return {
          limit: 3,
        };
      }
    },
    proposalTopVoters: {
      query: require("../../apollo/graphql/ProposalTopVoters.gql"),
      variables: () => {
        return {
          limit: 3,
        };
      }
    },
    topVoters: {
      query: require("../../apollo/graphql/TopVoters.gql"),
      variables: () => {
        return {
          limit: 5,
        };
      },
      watchLoading(isLoading) {
        this.votersLoading = isLoading;
      }
    },
    homeStatistics: {
      query: require("../../apollo/graphql/HomeStatistics.gql"),
      watchLoading(isLoading) {
        this.apiLoading = isLoading;
      }
    },
    proposalVoterReportDay: {
      query: require("../../apollo/graphql/proposalVoterReportDay.gql"),
      result() {
        this.setupChart();
      }
    }
  }
})
export default class DashboardPage extends Vue {
  private proposalTopLikes: any = {
    proposalNum: 0,
    proposals: []
  };
  private proposalTopVoters: any = {
    proposalNum: 0,
    proposals: []
  };
  private homeStatistics: any = {
    proposalNum: 0,
    stakers: 0,
    totalVotingPower: 0,
    voters: 0,
  };
  private proposalVoterReportDay: any = null;
  private quorumNeeded = "0";
  private config = {
    concurrentProposals: new BigNumber(0),
    dequeueFrequency: "",
    minDeposit: new BigNumber(0),
    queueExpiry: "",
    stageDurations: {
      Referendum: "",
      Execution: "",
    },
    participationParameters: {
      baseline: new BigNumber(0),
      baselineFloor: new BigNumber(0),
      baselineUpdateFactor: new BigNumber(0),
      baselineQuorumFactor: new BigNumber(0),
    }
  };
  private voteChart?: echarts.ECharts;

  private configLoading = false;
  private apiLoading = false;
  private votersLoading = false;
  private totalLocked = "";

  async loadData() {
    if (UserModule.contractKit) {
      this.configLoading = true;
      const [config, total] = await Promise.all([
        UserModule.contractKit.governanceContract.getHumanReadableConfig(),
        UserModule.contractKit.lockedContract.getTotalLockedGold(),
      ]);
      // calculate quorumNeeded
      this.config = config;
      // total network weight is total locked MAPs
      const needed = this.config
        .participationParameters.baseline
        .multipliedBy(this.config.participationParameters.baselineQuorumFactor)
        .multipliedBy(new BigNumber(total));
      this.quorumNeeded = weiBigNumberToNormalValueFixed(
        new BigNumber(needed.toFixed(0))
      ).toLocaleString();
      this.configLoading = false;
      console.log(total.toString());
      this.totalLocked = shortenNumber(weiBigNumberToNormalValueFixed(new BigNumber(total)));
    }
  }

  private setupChart() {
    const value = this.proposalVoterReportDay;
    const dates: string[] = [];
    const series: any = [];
    const lineData = {
      name: "votes",
      data: [] as number[],
      type: "line",
      smooth: true,
    };
    const arr: number[] = [];
    value.forEach((aData: any) => {
      dates.push(aData.currDate);
      arr.push(aData.voters);
    });
    lineData.data = arr;
    series.push(lineData);
    const option: EChartsOption = {
      xAxis: {
        type: "category",
        data: dates,
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: "{value}",
        },
        minInterval: 100,
        splitNumber: 5,
        maxInterval: 100000000000000,
      },
      legend: {
        data: ["votes"],
      },
      tooltip: {
        trigger: "axis",
      },
      series: series,
      grid: {
        left: "3%",
        right: "0%",
        bottom: "3%",
        containLabel: true,
      },
    };
    this.voteChart?.setOption(option);
  }

  mounted() {
    this.loadData();
    const chartDom = document.getElementById("voteChart")!;
    this.voteChart = echarts.init(chartDom);
  }
}
