
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { viewportToPixels } from '@/utils/tools';
import AvatarPixel from '@/components/AvatarPixel.vue';
@Component({
  components: { AvatarPixel },
})
export default class GovernanceProposalItem2 extends Vue {
  @Prop({
    default: false,
  })
  public isTitle!: boolean;

  @Prop({
  })
  public data!: any;

  public convertViewPort(input: string): number {
    return viewportToPixels(input);
  }
}
