
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import AvatarPixel from '@/components/AvatarPixel.vue';
import { viewportToPixels } from '@/utils/tools';
@Component({
  components: { AvatarPixel },
})
export default class GovernanceProposalItem extends Vue {
  @Prop({
    default: false,
  })
  public isTitle!: boolean;

  @Prop({
  })
  public data!: any;

  public convertViewPort(input: string): number {
    return viewportToPixels(input);
  }
  private tapItem() {
    this.$router.push("/governance/proposal/" + this.data.proposalId);
  }
}
