
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import AvatarPixel from '@/components/AvatarPixel.vue';
import { viewportToPixels } from '@/utils/tools';
@Component({
  components: { AvatarPixel },
})
export default class GovernanceVoterItem extends Vue {
  @Prop()
  private data!: any;

  public convertViewPort(input: string): number {
    return viewportToPixels(input);
  }

}
